<template>
  <div>
    <div class="recommend-desc">
      <div class="recommend-desc-detail">
        <div class="title">{{introduction.title}}</div>
        <div class="sub-title mt-2">{{introduction.desc}}</div>
        <div>
          <el-button type="primary" class="test-button" @click="handleClick">立即体验</el-button>
        </div>
      </div>
      <div class="img-wrapper tac">
        <el-image :src="introduction.imgSrc" width="442" height="333" style="width: 700px; height: 400px" fit="contain"/>
      </div>
    </div>
    <div class="recommend-desc2">
    </div>
  </div>
</template>

<script>
export default {
  name: 'item-introduction',
  props: {
    introduction: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('action')
    }
  }
}
</script>

<style scoped>
.recommend-desc {
  height: 450px;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
}
.recommend-desc-detail {
  width: 40%;
  height: 100%;
  padding: 20px 70px;
  overflow: hidden;
}
.recommend-desc-detail .title {
  font-size: 2rem;
  font-weight: 800;
  color: #4647BB;
}
.recommend-desc-detail .sub-title {
  height:180px;
  font-size: 1.4rem;
  color: #606266;
  line-height: 2.6rem;
  text-indent: 3rem;
}
.test-button {
  margin-top: 5px;
  font-size: 1.2rem;
  background-color: #4647BB;
  border: none;
}
.test-button::after {
  content: '→'
}
.img-wrapper {
  width: 40%;
}
</style>
