<template>
  <div class="">
    <div id="recommend" class="hi100" style="height: 100vh">
      <div class="bg wi100">
        <div class="banner-text">
          <div class="banner-title"><span class="stress-background">推荐式服务</span></div>
          <div class="banner-desc">
            <span>产品质量安全监测</span>
            ·
            <span>品牌价值在线测算</span>
            ·
            <span>服务协同与动态适配</span>
            <div class="mt-2">
              <span>企业信用在线评估</span>
              ·
              <span>企业综合质量诊断</span>
            </div>
          </div>
        </div>
        <div class="tar baogao-wrapper">
          <el-button @click="goTotal" class="baogao-button">点击查看</el-button>
        </div>
      </div>
      <div class="recommend-center">
        <div class="recommend-content">
          <div :class="activeItem === index ? 'recommend-item on':'recommend-item'"
               @mouseenter="handleMouseEnter(index)" v-for="(item ,index ) in tabs" @click="goResult(index)"
               :key="item.name">
            <div class="recommend-item-title">
              <div class="tac">
                <icon-svg :name="item.icon" class="icon-svg"></icon-svg>
              </div>
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="introduction-card">
          <item-introduction :introduction="curIntroduction"
                             @action="goResult(curIntroduction.key)"></item-introduction>
        </div>
      </div>
<!--      <div class="content-bottom"></div>-->
    </div>
  </div>
</template>
<script>

import ItemIntroduction from './components/item-introduction'

export default {
  name: 'recommend',
  components: {
    ItemIntroduction
  },
  data() {
    return {
      tabs: [
        {
          label: '产品质量安全监测',
          name: 'qs',
          icon: 'quality'
        },
        {
          label: '品牌价值在线测算',
          name: 'boc',
          icon: 'jiazhi'
        },
        {
          label: '服务协同与动态适配',
          name: 'sxd',
          icon: 'shipei'
        },
        {
          label: '企业信用在线评估',
          name: 'bog',
          icon: 'xinyong'
        },
        {
          label: '企业综合质量诊断',
          name: 'bd',
          icon: 'invstu'
        }
      ],
      componentList: [
        {
          title: '产品质量安全监测',
          key: 0,
          desc: '基于产品的用户评论数据，采用前沿的AI技术，深入分析和挖掘用户评论数据，提供全新的产品质量安全监测报告展示体验。',
          imgSrc: require('../../../assets/img/recommend/chanpinzhilianganquanjiance.png')
        },
        {
          title: '品牌价值在线测算',
          key: 1,
          desc: '基于企业内外部信息，运用多周期超额收益法、Interbrand方法对企业的品牌价值进行评价。',
          imgSrc: require('../../../assets/img/recommend/ppjzpj.png')
        },
        {
          title: '服务协同与动态适配',
          key: 2,
          desc: '利用企业画像，结合企业综合质量服务历史记录，构建深度神经网络模型，实现企业综合质量服务的动态适配与协同推荐。',
          imgSrc: require('../../../assets/img/recommend/dongtaishipei.png')
        },
        {
          title: '企业信用在线评估',
          key: 3,
          desc: '基于企业本年度财务数据、非财务数据等信息，以国标为基准构建信用评级指标体系，采用企业自评级、' +
              '专家评级以及大数据评级三种模式，对企业信用等级进行立体化评估。',
          imgSrc: require('../../../assets/img/recommend/xydjpj.png')
        },
        {
          title: '企业综合质量诊断',
          key: 4,
          desc: '以企业工商数据、电商评论数据、政府质量抽检报告数据为基础，综合赋权法、情感分析等方法为技术导向，' +
              '通过综合质量诊断系统为平台企业提供全面化的质量诊断服务。',
          imgSrc: require('../../../assets/img/recommend/ppjzzd.png')
        }
      ],
      activeItem: 0,
      curIntroduction: {}
    }
  },
  mounted() {
  },
  computed: {
    //获取用户id
    userId: {
      get() {
        return this.$store.state.user.id
      }
    },
    //存取用户登录状态
    loginStatus: {
      get() {
        return this.$store.state.user.loginStatus
      }
    }
  },
  created() {
    this.curIntroduction = this.componentList[0] || {}
  },
  watch: {
    activeItem(val) {
      let curList = this.componentList.filter(item => item.key === val)
      this.curIntroduction = curList && curList.length > 0 ? curList[0] : {}
    }
  },
  methods: {
    handleMouseEnter(activeItem) {
      this.activeItem = activeItem
    },
    goResult(index) {
      if (this.loginStatus) {
        if (index === 0) {
          this.$router.push({name: 'recommend-result-word2vec'})
        } else if (index === 1) {
          this.$router.push({name: 'recommend-result-brand'})
        } else if (index === 2) {
          this.$router.push({name: 'recommend-result-cqc'})
        }
        else if (index === 3) {
          this.$router.push({name: 'recommend-credit'})
        }
        else if (index === 4){
          this.$router.push({name: 'recommend-general-quality'})
        }
        else {
          this.$alert('功能陆续开放中，敬请期待，', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          })
        }
      } else {
        this.$confirm('功能使用需要登录，确定去登录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message.error('请您先登录')
          this.$router.push({name: 'login'})
        })
      }
      // this.$alert('功能陆续开放中，敬请期待，', '提示', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //   }
      // })
    },

    goTotal() {
      // this.$router.push({name: 'tech-analysis'})
      this.$router.push({name: 'bigScreen'})
    },
    // 跳转网址
    toStandardbzfw() {
      window.open('http://std.samr.gov.cn/')
    },
    toStandardzcfw() {
      window.open('http://gjzwfw.www.gov.cn/col/col644/index.html')
    },
    toStandardqyxycx() {
      window.open('http://www.gsxt.gov.cn/index.html')
    },
    toStandardzwqyml() {
      window.open('http://xwqy.gsxt.gov.cn/')
    },
    toStandardrzrkfw() {
      window.open('http://cx.cnca.cn/CertECloud/index/index/page')
    },
    toStandardsbjs() {
      window.open('https://tm.gongsibao.com/tm/tmdashboard')
    },
    toSCRM() {
      window.open('https://scrm.gongsibao.com/home.html')
    }
  }

}
</script>
<style scoped>
.banner-text {
  position: relative;
  float: left;
  width: 80%;
  height: 100%;
  padding: 50px 13%;
}

.banner-title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 800;
  /*text-shadow: 2px 0px #FFFF11;*/
  /*box-shadow: 1px 1px #FFFFFF ;*/
  color: #FFFFFF;
  border-radius: 10px;
}

.banner-desc {
  width: 600px;
  margin-top: 1.4rem;
  font-size: 1rem;
  /*font-weight: bold;*/
  line-height: 1.4rem;
  color: #FFFFFF;
}

.baogao-button {
  position: relative;
  border: none;
  top: 80px;
  left: -58px;
  width: 180px;
  height: 40px;
  font-size: 1.2rem;
  font-weight: 600;
  /*background-image: linear-gradient(#5866C3, #FFFFFF);*/
  background-color: #FFFFFF;
  color: #FF8D1A;
  /*text-align: center;*/
  box-shadow: 5px 5px 5px #C0C4CC;
}

.baogao-button:hover {
  background-color: #FF8D1A;
  color: #FFFFFF
}

.recommend-center {
  height: 100%;
  background-color: #FFFFFF;
}

.recommend-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  top: -60px;
  left: 13%;
  width: 74%;
  height: 120px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .15);
}

.recommend-item {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 19.5%;
  height: 100%;
  color: #5866C3;
  border-radius: 10px;
  /*background-color: #5866C3;*/
  /*border-radius: 10px;*/
}

.on {
  background-color: #5866C3;
  color: #FFFFFF;
}

.recommend-item:nth-child(1) {
  border-radius: 10px 0px 0px 10px;
}

.recommend-item:nth-child(2) {
  border-radius: 0px;
}

.recommend-item:nth-child(3) {
  border-radius: 0px;
}

.recommend-item:nth-child(4) {
  border-radius: 0px;
}

.recommend-item:nth-child(5) {
  border-radius: 0px 10px 10px 0px;
}

.recommend-item:hover {
  background-color: #5866C3;
  color: #FFFFFF;
}

.recommend-item-title {
  font-size: 1rem;
  font-weight: bold;
  /*color: #FFFFFF;*/
  margin: auto;
}

.baogao-wrapper {
  border: hidden;
  position: relative;
  top: 50px;
  right: 12%;
  width: 272px;
  height: 158px;
  float: right;
  background-image: url("../../../assets/img/recommend/baogao3.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.zxw-nav {
  /*background: linear-gradient(90deg, #35bce2, #409eff);*/
  background: white;
  line-height: 2.4rem;
  width: 100%;
  outline: none;
}

.zxw-nav .wrap {
  width: 1300px;
  margin: 0 auto;
}

.zxw-nav li {
  width: 7rem;
  color: #404040;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.zxw-nav li:hover,
.zxw-nav li.on {
  background: #69c2ff;
  font-size: 15px;
  color: white;
}

.pop-aside-wrap {
  height: 415px;
  /*padding-right: 50px;*/
  position: relative;
  overflow: hidden;
  /*line-height: 32px;*/
  transition: all 0.3s;
}

.pop-aside-wrap-expand {
  height: calc(100vh - 190px - 24px);
  /*overflow-y: auto;*/
  padding-bottom: 24px;
}

.el-popper[x-placement^='right'] {
  margin-left: 0;
  border-radius: 0;
  padding-left: 20px;
}

.menu-box .tit {
  color: white;
  width: 200px;
  line-height: 2.4rem;
  padding-left: 1.4rem;
  background: #409eff;
  border-radius: 10px 10px 0 0;
  /*margin-top: -2.4rem;*/
  cursor: pointer;
}

.menu-box {
  position: absolute;
  top: 50px;
  z-index: 1999;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.menu-box .menu {
  width: 200px;
  height: 408px;
  opacity: 1;
  transform: scale(1, 1);
  transform-origin: top left;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.menu-box .menu.hide {
  height: 0;
  width: 0;
  transform: scale(0, 0);
  opacity: 0;
}

.menu-box .menu > div {
  /*min-height: 452px;*/
}

.menu .tab .iconfont {
  font-size: 20px;
  width: 26px;
  margin-right: 6px;
  font-weight: normal;
}

.menu .tab-box {
  padding-top: 10px;
  padding-left: 0;
}

.menu .tab-box .tab {
  height: 48px;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  width: 100%;
  padding: 0 5px 0 13px;
  position: relative;
}

.menu .tab-box .tab:hover,
.menu .tab-box .tab.on {
  /*color: #409eff;*/
  background: #ffffff;
}

.menu .tab-box .tab:hover:before,
.menu .tab-box .tab.on:before {
  /*color: #409eff;*/
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 42px;
  width: 100%;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
  /*background: #ffffff;*/
  z-index: -1;
}

.menu-pop {
  height: 452px;
  position: absolute;
  left: 199px;
  top: 0;
  width: 200px;
  background: #fff;
  /*min-width: 200px !important;*/
  /*max-width: 750px !important;*/
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: -1;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  transition: width 0.3s ease-in-out;
}

.box-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.pop-aside-wrap .pop-title {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}

.pop-aside-wrap .pop-title:hover {
  color: #409eff;
}

.pop-aside-wrap .second-list {
  cursor: pointer;
}

.pop-aside-wrap .second-wrap {
  /*flex-wrap: nowrap;*/
}

.pop-aside-wrap .second-list:hover {
  color: #409eff;
}

.menu .icon-jiantou-you {
  font-size: 12px !important;
}

/*服务贴边栏样式*/
.menu-box.fixed-left {
  position: fixed;
  left: 40px;
  top: 140px;
  border-radius: 0 10px 10px 10px;
}

.menu-box.fixed-left .el-icon-menu {
  margin-right: 6px;
  margin-bottom: 6px;
  margin-top: 10px;
}

.menu-box.fixed-left .tit {
  position: absolute;
  left: -40px;
  width: 40px;
  height: 200px;
  padding: 8px;
  flex-direction: column;
  line-height: 1.2rem;
  border-radius: 0;
}

/*需求弹框覆盖样式*/
.el-dialog /deep/ .el-dialog__footer {
  padding-top: 0;
}

.el-dialog /deep/ .el-dialog__header {
  padding-bottom: 0;
}

.icon-svg-menu {
  width: 24px;
  margin-right: 5px;
  text-align: center;
  font-size: 16px;
  color: inherit !important;
  transition: inline-block 0.3s, left 0.3s, width 0.3s, margin-left 0.3s,
  font-size 0.3s;
}

.zxw-nav >>> .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner-wrap .txt {
  line-height: 2vw;
  text-align: justify;
}

.banner-wrap .wrap {
  width: 80%;
  margin: 0 auto;
  height: 100%;
}

.bg {
  height: calc(100vh - 150px);
  width: 100%;
  background: #d1fff3;
  margin: 0;
  height: 320px;
  /*min-height: 40vh;*/
  /*position: relative;*/
  background-image: url("../../../assets/img/recommend/tuijianshi-banner2.png");
  /*background-color: #e8e8e8;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /*padding: 50px 10%;*/
}

.icon-svg {
  font-size: 3.2rem;
  margin-top: 5px;
  margin-bottom: 5px;
}

.stress-background {
  padding: 0px 8px;
  background-color: #FF8D1A;
}

.introduction-card {
  /*animation: fadeoutR 1s linear;*/
}

/* 淡出-向右 */
@-webkit-keyframes fadeoutR {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
}

@-moz-keyframes fadeoutR {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
}

@-ms-keyframes fadeoutR {
  0% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -ms-transform: translateX(100px);
  }
}

@keyframes fadeoutR {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

</style>
